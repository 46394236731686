import { UserManagerSettings } from 'oidc-client';
import { AppConfig, Env } from '@app/app.config';

export default class TrainingConfig extends AppConfig {
    constructor() {
        super('TRN');
    }
    public static env = Env.PROD;
    public competitionAutocompleteApiEndpoint = this.competitionApiEndpoint + '/autocomplete';
    // Azure file storage paths
    public azureFilesDefaultFileShare = 'map-resources';
    public azureFilesDefaultParentDirectory = 'map-resources.webroot';
    public azureFilesSponsorStripsDirectory = 'sponsorstrips';
    public azureFilesCompetitionsDirectory = 'competitions';
    public azureFilesFormRegistrationTemplateDirectory = 'form-registration-templates';
    public alfrescoBaseContentUrl = 'https://map-alfresco.training.fiba.com/alfresco/api/-default-/public/cmis/versions/1.1/atom/content';
    private readonly BASE_URL = 'https://map.training.fiba.com';
    // Authentication
    public oidcSettings: UserManagerSettings = {
        authority: 'https://authentication.training.fiba.com',
        client_id: 'map.fiba.com',
        redirect_uri: this.BASE_URL + '/auth-handler',
        silent_redirect_uri: this.BASE_URL + '/auth-handler-silent',
        post_logout_redirect_uri: this.BASE_URL,
        response_type: 'id_token token',
        scope: 'openid profile MAP',
        automaticSilentRenew: false,
        silentRequestTimeout: 15000, // ms
    };
    // URLs and API endpoints
    public baseUrl = this.BASE_URL;
    private readonly BASE_PUBLIC_URL = 'https://map-public.training.fiba.com';
    public basePublicUrl = this.BASE_PUBLIC_URL;
    private readonly PUB_API_BASE_URL = 'https://map-api.training.fiba.com/public';
    // Public
    public publicDocumentApiEndpoint = this.PUB_API_BASE_URL + '/documents';
    public publicApiEndpoint = this.PUB_API_BASE_URL;
    private readonly API_BASE_URL = 'https://map-api.training.fiba.com';
    public itemApiEndpoint = this.API_BASE_URL + '/items';
    public configurationContactEmailCategoryApiEndpoint = this.API_BASE_URL + '/configuration-contact-categories';
    public itemCategoriesApiEndpoint = this.API_BASE_URL + '/item-categories';
    public countryApiEndpoint = this.API_BASE_URL + '/countries';
    public azureFileApiEndpoint = this.API_BASE_URL + '/azure-files';
    public documentApiEndpoint = this.API_BASE_URL + '/documents';
    public azureDocumentApiEndpoint = this.API_BASE_URL + '/documents/azure-documents';
    public personApiEndpoint = this.API_BASE_URL + '/persons';
    public personAutocompleteApiEndpoint = this.API_BASE_URL + '/persons/autocomplete';
    // Removed for PBI #12259
    //public personAutocompleteApiV2Endpoint = this.API_BASE_URL + '/v2/persons/autocomplete';
    public personStatusApiEndpoint = this.API_BASE_URL + '/persons/statuses';
    public agentRelationsApiEndpoint = this.API_BASE_URL + '/agent-relations';
    public agentRelationSharesApiEndpoint = this.API_BASE_URL + '/agent-relation-shares';
    public templateApiEndpoint = this.API_BASE_URL + '/templates';
    public notificationApiEndpoint = this.API_BASE_URL + '/notifications';  
    // User Management
    public policiesApiEndpoint = this.API_BASE_URL + '/auth/policies';
    public userApiEndpoint = this.API_BASE_URL + '/users';
    public userProfileApiEndpoint = this.API_BASE_URL + '/user-profile';
    public userStatusApiEndpoint = this.API_BASE_URL + '/users/statuses';
    public userRolesApiEndpoint = this.API_BASE_URL + '/auth/roles';
    public termsAndConditionsApiEndpoint = this.API_BASE_URL + '/users/terms-and-conditions';
    public currentUserAccessApiEndpoint = this.API_BASE_URL + '/auth/user/access';
    public currentUserOrganisationApiEndpoint = this.API_BASE_URL + '/auth/user/organisation';
    public currentUserAuthorisationApiEndpoint = this.API_BASE_URL + '/auth/user/authorisations';
    public roleFamilyApiEndpoint = this.API_BASE_URL + '/role-families';
    public roleTypeApiEndpoint = this.API_BASE_URL + '/role-types';
    public roleApiEndpoint = this.API_BASE_URL + '/roles';
    public zoneApiEndpoint = this.API_BASE_URL + '/zones';
    // Organisation / Club / National Federation
    public organisationAgenciesApiEndpoint = this.API_BASE_URL + '/organisations/organisation-agencies';
    public organisationApiEndpoint = this.API_BASE_URL + '/organisations';
    public personRoleApiEndpoint = this.API_BASE_URL + '/person-role';
    public organisationAutocompleteApiEndpoint = this.API_BASE_URL + '/organisations/autocomplete';
    public organisationStatusApiEndpoint = this.API_BASE_URL + '/organisations/statuses';
    public nationalFederationApiEndpoint = this.API_BASE_URL + '/national-federations';
    public nationalFederationAutocompleteApiEndpoint = this.API_BASE_URL + '/national-federations/autocomplete';
    public nationalFederationMembershipStatusApiEndpoint = this.API_BASE_URL + '/national-federations/membership-statuses';
    public clubApiEndpoint = this.API_BASE_URL + '/clubs';
    public clubAutocompleteApiEndpoint = this.API_BASE_URL + '/clubs/autocomplete';
    public clubStatusApiEndpoint = this.API_BASE_URL + '/clubs/statuses';
    public rankingApiEndpoint = this.API_BASE_URL + '/ranking';
    // License Request
    public licenseRequestApiEndpoint = this.API_BASE_URL + '/license-requests';
    // Coach License Request
    public coachLicenseRequestApiEndpoint = this.API_BASE_URL + '/coach-license-requests';
    public coachLicenseApiEndpoint = this.API_BASE_URL + '/coach-licenses';
    // Player License
    public playerLicenseApiEndpoint = this.API_BASE_URL + '/player-licenses';
    public playerLicenseStatusApiEndpoint = this.API_BASE_URL + '/player-licenses/statuses';
    public playerLicenseSummaryApiEndpoint = this.API_BASE_URL + '/player-licenses/summary';
    // Player Release
    public playerReleaseApiEndpoint = this.API_BASE_URL + '/competitions/player-release';
    // Basketball License
    public basketballLicenseApiEndpoint = this.API_BASE_URL + '/basketball-licenses';
    // Player
    public playerApiEndpoint = this.API_BASE_URL + '/players';
    // Basketball role family
    public basketballPersonsApiEndpoint = this.API_BASE_URL + '/basketball-persons';
    // Reporting
    public reportingApiEndpoint = this.API_BASE_URL + '/reports';
    public reportingImageApiEndpoint = this.API_BASE_URL + '/reports/imageUrl';
    public integratedReportingApiEndpoint = this.API_BASE_URL + '/proxy-reports';
    // Competitions
    public competitionApiEndpoint = this.API_BASE_URL + '/competitions';
    public competitionStatusApiEndpoint = this.API_BASE_URL + '/competitions/statuses';
    public competitionStandingHeadersApiEndpoint = this.API_BASE_URL + '/competitions/competition-standing-headers';
    public competitionTeamStatusApiEndpoint = this.API_BASE_URL + '/competitions/teams/statuses';
    public competitionTeamDocumentStatusApiEndpoint = this.API_BASE_URL + '/competitions/teams/documents/statuses';
    public milestoneConfigurationApiEndpoint = this.API_BASE_URL + '/competitions/milestones-configuration';
    public technicalMeetingStatusApiEndpoint = this.API_BASE_URL + '/technical-meetings/statuses';
    public finalRosterStatusApiEndpoint = this.API_BASE_URL + '/final-rosters/statuses';
    public finalRosterMemberStatusApiEndpoint = this.API_BASE_URL + '/final-rosters/members/statuses';
    // Venues
    public venueApiEndpoint = this.API_BASE_URL + '/venues';
    public venueStatusApiEndpoint = this.API_BASE_URL + '/venues/statuses';
    public venueAutocompleteApiEndpoint = this.API_BASE_URL + '/venues/autocomplete';
    // Events
    public eventApiEndpoint = this.API_BASE_URL + '/events';
    // Financial Reporting
    public financialReportingApiEndpoint = this.API_BASE_URL + '/financial-reportings';
    public financialDataPackageApiEndpoint = this.API_BASE_URL + '/financial-data-packages';
    // Sanctions
    public sanctionApiEndpoint = this.API_BASE_URL + '/sanctions';
    public sanctionApplicationApiEndpoint = this.API_BASE_URL + '/sanction-applications';
    // Coaches
    public coachApiEndpoint = this.API_BASE_URL + '/coaches';
    public coachAutocompleteApiEndpoint = this.API_BASE_URL + '/coaches/autocomplete';
    public coachAutocompleteApiV2Endpoint = this.API_BASE_URL + '/v2/coaches/autocomplete';
    // Game Officials
    public gameOfficialApiEndpoint = this.API_BASE_URL + '/game-officials';
    public gameOfficialAutocompleteApiEndpoint = this.API_BASE_URL + '/game-officials/autocomplete';
    // Agents
    public agentApiEndpoint = this.API_BASE_URL + '/agents';
    // Transfer requests
    public transferRequestApiEndpoint = this.API_BASE_URL + '/transfers/requests';
    // Official Licenses
    public officialLicenseApiEndpoint = this.API_BASE_URL + '/official-licenses';
    public officialLicensePeriodApiEndpoint = this.API_BASE_URL + '/official-licenses/periods';
    public officialLicenseRegistrationApiEndpoint = this.API_BASE_URL + '/official-licenses/requests';
    // Payment
    public paymentApiEndpoint = this.API_BASE_URL + '/payments';
    // Form registrations
    public formRegistrationTemplateApiEndpoint = this.API_BASE_URL + '/form-registration-templates';
    public venueFormRegistrationApiEndpoint = this.API_BASE_URL + '/venue-form-registrations';
    public competitionTeamFormRegistrationApiEndpoint = this.API_BASE_URL + '/competition-team-form-registrations';
    public formRegistrationBaseApiEndpoint = this.API_BASE_URL + '/form-registrations';
    public gameInformationFormRegistrationApiEndpoint = this.API_BASE_URL + '/game-information-form-registrations';
    public technicalDelegateReportingFormRegistrationApiEndpoint = this.API_BASE_URL + '/technical-delegate-reporting-form-registrations';
    public formRegistrationSiteVisitApiEndpoint = this.API_BASE_URL + '/site-visit-form-registrations';
    public equipmentFormRegistrationApiEndpoint = this.API_BASE_URL + '/equipment-form-registrations';
    public equipmentTestResultFormRegistrationApiEndpoint = this.API_BASE_URL + '/equipment-test-result-form-registrations';
    public equipmentTestResultApplicationApiEndpoint = this.API_BASE_URL + '/test-result-form-registrations';
    //official registrations / technical delegate candidates
    public officialRegistrationApiEndpoint = this.API_BASE_URL + '/official-registrations';
    // insurance policy
    public insurancePolicyApiEndpoint = this.API_BASE_URL + '/insurance-policies';
    public insurancePolicyStatusApiEndpoint = this.API_BASE_URL + '/insurance-policies/statuses';
    // cart management
    public cartApiEndpoint = this.API_BASE_URL + '/carts';
    // Equipment
    public equipmentApiEndpoint = this.API_BASE_URL + '/equipment';
    public approvalCategoryApiEndpoint = this.API_BASE_URL + '/equipment/approval-category';
    public feeApiEndpoint = this.API_BASE_URL + '/equipment/fee';
    public equipmentProductApplicationApiEndpoint = this.API_BASE_URL + '/equipment/product-application';
    public equipmentPartnerApplicationApiEndpoint = this.API_BASE_URL + '/equipment/partner-application';
    public equipmentFibaPartnerApiEndpoint = this.API_BASE_URL + '/equipment/fiba-partner';
    public equipmentProductApiEndpoint = this.API_BASE_URL + '/equipment/product';
    public equipmentDashboardApiEndpoint = this.API_BASE_URL + '/equipment/dashboard';
    public equipmentTestInstituteApiEndpoint = this.API_BASE_URL + '/equipment/test-institute';
    public equipmentAgreementApiEndpoint = this.API_BASE_URL + '/equipment/agreement';
    public equipmentPaymentApiEndpoint = this.API_BASE_URL + '/equipment/payment';
    //external website
    public webCISUrl = 'https://webcis.staging.fiba.basketball/';
    public lsiUrl = 'https://lsi-monitor.staging.fiba.basketball/';
}
