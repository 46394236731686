import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiListResponse, EquipmentApprovalCategoryDTO, ApprovalCategoryFilters, EquipmentApprovalCategoryListItemDTO, EquipmentApprovalCategoryStatusDTO, EquipmentApprovalCategoryItemDTO } from '@fiba/models';

import { HttpService } from '@fiba/data-services/utils/http.service';
import { AppConfig } from '@app/app.config';
import { DataCachedService } from '../utils/data-cached.service';

@Injectable({
    providedIn: 'root',
})
export class ApprovalCategoryDataService {
    protected approvalCategoryApiEndpoint: string;

    constructor(
        protected config: AppConfig,
        protected dataService: DataCachedService<EquipmentApprovalCategoryDTO>,
        protected http: HttpService) {
        this.approvalCategoryApiEndpoint = config.approvalCategoryApiEndpoint;
    }

    public fetchList(filters: ApprovalCategoryFilters): Observable<ApiListResponse<EquipmentApprovalCategoryListItemDTO>> {
        return this.http.get(`${this.approvalCategoryApiEndpoint}/list-approval-category`, filters.getSearchParams());
    }

    public fetchStatuses(): Observable<EquipmentApprovalCategoryStatusDTO[]> {
        return this.http.get(`${this.approvalCategoryApiEndpoint}/status`);
    }

    public fetchApprovalCategory(approvalCategoryId: number): Observable<EquipmentApprovalCategoryDTO> {
        return this.http.get(`${this.approvalCategoryApiEndpoint}/${approvalCategoryId}`);
    }

    public createApprovalCategoryInformation(model: EquipmentApprovalCategoryDTO): Observable<EquipmentApprovalCategoryDTO> {
        return this.http.post(`${this.approvalCategoryApiEndpoint}/information`, model);
    }

    public updateApprovalCategoryInformation(model: EquipmentApprovalCategoryDTO): Observable<any> {
        return this.http.put(`${this.approvalCategoryApiEndpoint}/${model.equipmentApprovalCategoryId}/information`, model);
    }

    public fetchParentApprovalCategories(): Observable<EquipmentApprovalCategoryDTO[]> {
        return this.http.get(`${this.approvalCategoryApiEndpoint}/parent-approval-categories`);
    }

    public fetchAllApprovalCategories(): Observable<EquipmentApprovalCategoryDTO[]> {
        return this.http.get(`${this.approvalCategoryApiEndpoint}/all`);
    }

    public fetchAllApprovalCategoriesForSelector(): Observable<EquipmentApprovalCategoryListItemDTO[]> {
        return this.http.get(`${this.approvalCategoryApiEndpoint}/all-for-selector`);
    }

    public fetchApprovalCategoryItems(approvalCategoryId: number, itemCode: string): Observable<EquipmentApprovalCategoryItemDTO[]> {
        return this.http.get(`${this.approvalCategoryApiEndpoint}/${approvalCategoryId}/approval-category-items/${itemCode}`);
    }

    public fetchApprovalCategoriesByUserOrganisation(): Observable<EquipmentApprovalCategoryDTO[]> {
        return this.http.get(`${this.approvalCategoryApiEndpoint}/all-by-user-organisation`);
    }
    public fetchApprovalCategoriesByOrganisation(organisationId: number = undefined): Observable<EquipmentApprovalCategoryDTO[]> {
        return this.http.get(`${this.approvalCategoryApiEndpoint}/all-by-organisation/${organisationId}`);
    }

    public fetchTreeviewAprovalCategories(): Observable<EquipmentApprovalCategoryDTO[]> {
        return this.dataService.fetchEntities(this.approvalCategoryApiEndpoint + '/treeview');
    }
}
