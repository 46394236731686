import { Injector, Input, Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';

import { Logger } from '@fiba/utils/logger';
import { FibaAjaxSelectBaseComponent } from '@fiba/forms';
import { ZoneDTO } from '@fiba/models';
import { ZoneDataService } from '@fiba/data-services';

@Component({
    selector: 'fibaSelectZone',
templateUrl: '../../forms/base/fiba-select-base.component.html',    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaSelectZoneComponent),
            multi: true
        }
    ],
    host: { class: 'fiba-input' }
})
export class FibaSelectZoneComponent extends FibaAjaxSelectBaseComponent<ZoneDTO> {

    private ZONE_FIBA_WORLD_CODE = 'W';

    protected _firstLevel: boolean;
    @Input() protected set firstLevel(value: boolean) {
        this._firstLevel = value;
        this.handleRestrictedItems();
    }

    constructor(protected injector: Injector, protected dataService: ZoneDataService) {
        super(injector);
        this._valueField = 'zoneId';
        this._textField = 'officialName';
    }

    public getObservable(): Observable<ZoneDTO[]> {
        return this.dataService.fetchZones();
    }

    protected handleRestrictedItems(): void {
        if (!this._firstLevel || !this._originalItems) {
            this._filteredItems = this._originalItems;
            return;
        }
        this._filteredItems = this._originalItems.filter(item => item.code === this.ZONE_FIBA_WORLD_CODE || this.isZoneFirstChildOfCode(item, this.ZONE_FIBA_WORLD_CODE));
    }

    private isZoneFirstChildOfCode(zone: ZoneDTO, parentCode?: string): boolean {
        return zone !== null &&
            zone.zoneRelationSecondZone !== null &&
            (zone.zoneRelationSecondZone.findIndex(zr => zr.firstZone !== undefined && zr.firstZone.code === parentCode) !== -1);
    }
}
