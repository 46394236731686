export class DocumentGenericTypes {
    public static readonly AGENT_REGISTRATION = 'Agent Registration';
    public static readonly BASKETBALL_LICENSE = 'Basketball License';
    public static readonly COMPETITION_TEAM = 'Competition Team';
    public static readonly LICENSE_REQUEST = 'License Request';
    public static readonly LONG_LIST_MEMBER = 'LongListMember';
    public static readonly NATIONALITY = 'Nationality Identification';
    public static readonly OFFICIAL_LICENSE_PERIOD = 'Official License Period Document';
    public static readonly OFFICIAL_LICENSE_REGISTRATION = 'Official License Registration Document';
    public static readonly TECHNICAL_MEETING_COMPETITION_TEAM = 'Technical Meeting Competition Team';
    public static readonly ORGANISATION = 'Organisation';
    public static readonly PERSON = 'Person';
    public static readonly PLAYER_LICENSE = 'Player License';
    public static readonly PLAYER_RELEASE = 'Player Release';
    public static readonly SANCTION_APPLICATION = 'Sanction Application';
    public static readonly TEAM_REGISTRATION_DOCUMENT = 'Team Registration Document';
    public static readonly TRANSFER = 'Transfer';
    public static readonly VENUE = 'Venue';
    public static readonly INSURANCE_POLICY = 'Insurance Policy';
    public static readonly APPROVAL_CATEGORY = 'Approval Category';
    public static readonly EQUIPMENT_AGREEMENT_REPORT = 'Agreement Report';
}
