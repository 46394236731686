import * as moment from 'moment';

/**
 * Filterable Class used for single filters in dropdown inputs
 */
export class Filterable {
    public value: any; // value picked in the dropdown
    protected filterName: string;
    protected _idField: string;
    public get idField() {
        return this._idField;
    }

    /**
     * If the field containing the id has the same name than the filter name, you can omit the idField parameter
     *
     * @param filterName Name of the filter in the API
     * @param idField Name of the field (in the JS object) containing the ID you want to filter
     */
    constructor(filterName: string, idField: string = null) {
        this.filterName = filterName;
        this._idField = idField === null ? filterName : idField;
    }

    public isSet(): boolean {
        return typeof this.value !== 'undefined' && this.value !== null;
    }

    public equals(id: any): boolean {
        return this.value[this._idField] === id;
    }

    public getFilter(): string {
        return this.value[this._idField].toString();
    }

    public reset(): void {
        this.value = null;
    }

    public setValue(value: any): void {
        this.value = value;
    }

    public setValuePrimitive(value: any): void {
        this.value[this._idField] = value;
    }

    public getValue(): any {
        if (this.isSet()) {
            return this.value[this._idField];
        }
    }
}

/**
 * FilterableArray Class used for filters in multiselect inputs
 */
export class FilterableArray {
    public value: any[]; // value picked in the dropdown
    protected filterName: string;
    protected idField: string;

    /**
     * If the field containing the id has the same name than the filter name, you can omit the idField parameter
     *
     * @param filterName Name of the filter in the API
     * @param idField Name of the field (in the JS object) containing the ID you want to filter
     */
    constructor(filterName: string, idField: string = null) {
        this.filterName = filterName;
        this.idField = idField === null ? filterName : idField;
        this.value = [];
    }

    /**
     *  Gets the filters
     *
     * @returns an array with the values of the filter converted into string
     */
    public getFilter(): string[] {
        if (this.value) {
            return this.value.map((v) =>
                v[this.idField].toString(),
            );
        } else {
            return [];
        }
    }

    /**
     * Gets the filter Name
     *
     * @returns the filter name
     */
    public getFilterName(): string {
        return this.filterName;
    }

    /**
     * Gets whether there is any filter with the specified id
     *
     * @param id of the filter
     * @returns true if the filter id is present in the set of selected filters.
     */
    public contains(id: any): boolean {
        return this.value && this.value.filter((x) => x[this.idField] === id).length > 0;
    }

    /**
     * Gets whether there is any filter assigned
     *
     * @returns true if there is any filter, false otherwise
     */
    public any(): boolean {
        return this.value && this.value.length > 0;
    }

    /**
     * Resets the filter values
     */
    public reset(): void {
        this.value = [];
    }

    /**
     * set the filter value
     */
    public setFilterValue(value: any[]): void {
        this.value = value;
    }

    public addFilterValue(value: any): void {
        this.value.push(value);
    }
}

const DEFAULT_TAKE = 30;

export class BaseFilters {
    public take: number;
    public skip: number;
    public sortOrder: string;
    public sortBy: string;

    constructor(take = DEFAULT_TAKE, skip = 0) {
        this.take = take;
        this.skip = skip;
    }

    //
    // @description : Represent the parameters values from the properties.
    //                The properties started with '__' will be ignored
    //
    public getSearchParams(completeDateTime: boolean = false): { [key: string]: string | string[] } {
        const params: { [key: string]: string | string[] } = {};

        for (const filterKey in this) {
            if (this.hasOwnProperty(filterKey)) {
                if (!filterKey.startsWith('__')) {
                    const filter: any = this[filterKey];
                    switch (typeof filter) {
                        case 'number':
                        case 'string':
                        case 'boolean':
                            params[filterKey] = String(filter);
                            break;
                        case 'object':
                            if (filter instanceof FilterableArray) {
                                params[filter.getFilterName()] = filter.getFilter();
                            } else if (filter instanceof Date && completeDateTime) {
                                // Returns ISO string without ms and offset : 2022-08-25T23:59:59
                                params[filterKey] = moment(filter).toISOString(true).split('.')[0];
                            } else if (filter instanceof Date) {
                                params[filterKey] = moment.utc(filter).format('D-MMM-YYYY');
                            } else if (filter && filter.value) {
                                params[filter.filterName] = String(filter.value[filter.idField]);
                            }
                            break;
                        default:
                            break;
                    }
                }
            }
        }
        return params;
    }

    public reset(): void {

    }

    public clone(): any {
        return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
    }
}
