import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';

import { ReportDTO, TechReportingDTO } from '@fiba/models';

import { IFileData } from '../';
import { HttpService } from '../utils/http.service';
import {AppConfig} from '@app/app.config';

interface ILicenseReportParams {
    seasonFrom?: string;
    seasonTo?: string;
    zone?: string;
}

interface ITechnicalMeetingReportParams {
    season?: number;
    logoUrl?: string;
    competitionId?: number;
    finalRosterIds?: string;
    frcIdsctIds?: string;
    preliminaryRosterIds?: string;
    preliminaryRosterConfigurationId?: number;
    finalRosterId?: number;
}

interface ITelerikRosterReportParams {
    // Generic
    LogoUrl?: string;
    rp_Season?: number;
    rp_PlayersCoachs?: string;
    rp_Competition?: number;
    rp_CompetitionCode?: string | number;
    rp_TypeDelegationRoster?: string;
    competitionTeamCodes?: string;

    // Specific Preliminary/Final roster players per period
    rp_CompetitionTeam?: string;
    rp_DateFrom?: string;
    rp_DateTo?: string;

    // Specific Preliminary/Final roster players
    rp_CompetitionLevel?: string;
    rp_GameDay?: number;
}

interface IStartListReportParams {
    competitionId?: number;
    gameId?: number;
    season?: number;
    roundId?: number;
    groupPairingCode?: string;
    gameNumber?: string;
}

interface IBoxScoreReportParams {
    competitionId?: number;
    gameId?: number;
}

interface IPlayByPlayReportParams {
    competitionId?: number;
    gameId?: number;
    season?: number;
    competitionCode?: string;
    gameName?: string;
}

interface ILineupAnaylsisReportParams {
    competitionId?: number;
    gameId?: number;
    season?: number;
    competitionCode?: string;
    gameName?: string;
}

interface IShotChartReportParams {
    competitionId?: number;
    gameId?: number;
    season?: number;
    competitionCode?: string;
    gameName?: string;
}

interface ICompetitionScheduleReportParams {
    competitionId?: number;
    competitionLogoUrl?: string;
    competitionCode?: string;
    season?: number;
    roundIds?: string;
    groupPairingIds?: string;
    showGroupsAndRoundsInFileName?: boolean;
}

interface ITournamentSummaryReportParams {
    competitionId?: number;
    competitionCode?: string;
    competitionLogoUrl?: string;
    dateFormatted?: string; // ?
    gameDay?: string; // ?
    season?: number;
    brackets?: boolean;
    nbPeriod?: string; // ?
}

interface ITeamStatisticsReportParams {
    competitionId?: number;
    competitionLogoUrl?: string;
    competitionCode?: string;
    season?: number;
    date?: string;
}

interface IPlayerStatisticsReportParams {
    competitionId?: number;
    competitionLogoUrl?: string;
    season?: number;
    date?: string;
}

interface IHeadToHeadReportParams {
    competitionId?: number;
    competitionLogoUrl?: string;
    competitionCode?: string;
    season?: number;
    fromSeason?: number;
    toSeason?: number;
}

interface IAccumulatedStatisticsReportParams {
    competitionId?: number;
    competitionLogoUrl?: string;
    season?: number;
    competitionCode?: string;
    date?: number; // format is yyyy E^4 + MM E^2 + dd , e.g. 20180518 (as a number)
    competitionTeamIds?: string; // string of array's comma separated values
}

interface IRosterDownloadReportParams {
    competitionId?: number;
    preliminaryRosterStructureLevelId?: number;
    //roundId?: number;
    //windowId?: number;
    gameDay?: number;
    competitionTeamIds?: number[];
    isGameReportsContext?: boolean;
}

interface ILetterOfClearanceParams {
    transferLetterId?: number;
    transferRequestId?: number;
}

interface IPlayerInsuranceCoverageParams {
    preliminaryRosterMemberId?: number;
}
interface ICompetitionInsuredPlayersParams {
    preliminaryRosterConfigurationId?: number;
}

interface ITeamInsuranceCoverageParams {
    preliminaryRosterId?: number;
}

interface ISiteVisitReportParams {
    siteVisitFormRegistrationId?: number;
    alfrescoBaseUrl?: string;
    reportingImageApiEndPoint?: string;
    isBlankReport?: boolean;
}

interface IFormRegistrationReportParams {
    formRegistrationId?: number;
    isBlankReport?: boolean;
}

interface ITechnicalMeetingOperatorLastLoginReportParams {
    competitionId?: number;
}

interface IRankingReportParams {
    rankingId?: number;
}

interface IPotentialGroupRankingReportParams {
    groupPairingIds?: number[];
}

interface IAgreementReportParams {
    equipmentAgreementId?: number;
    equipmentApprovalCategoryId?: number;
}

@Injectable({
    providedIn: 'root',
})
export class ReportDataService {

    protected reportingApiEndpoint: string;
    protected integratedReportingApiEndpoint: string;
    protected reportingImageApiEndPoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.reportingApiEndpoint = config.reportingApiEndpoint;
        this.integratedReportingApiEndpoint = config.integratedReportingApiEndpoint;
        this.reportingImageApiEndPoint = config.reportingImageApiEndpoint;
    }

    public downloadALicensesReport(seasonFrom: number, seasonTo: number, zone: number): Observable<any> {
        const params = {} as ILicenseReportParams;
        if (seasonFrom) {
            params.seasonFrom = '' + seasonFrom;
        }
        if (seasonTo) {
            params.seasonTo = '' + seasonTo;
        }
        if (zone) {
            params.zone = '' + zone;
        }

        return this.http.getFileData(`${this.reportingApiEndpoint}/player-license/a-licenses`, params as { [key: string]: string | string[] });
    }

    public downloadTechnicalMeetingExtractReportForNationalCompetition(season: number, competitionId: number, frcIdsctIds: string[]): Observable<IFileData> {
        const params = {} as ITechnicalMeetingReportParams;

        params.season = season;
        params.competitionId = competitionId;
        params.frcIdsctIds = frcIdsctIds.join(',');

        return this.http.getFileData(`${this.integratedReportingApiEndpoint}/technical-meeting-extract`, params as { [key: string]: string | string[] });
    }

    public downloadTechnicalMeetingExtractReportForClubCompetition(
        season: number,
        competitionId: number,
        preliminaryRosterIds: number[],
        preliminaryRosterConfigurationId: number,
        finalRosterId: number
    ): Observable<IFileData> {
        const params = {} as ITechnicalMeetingReportParams;

        params.season = season;
        params.competitionId = competitionId;
        params.preliminaryRosterIds = preliminaryRosterIds.join(',');
        params.preliminaryRosterConfigurationId = preliminaryRosterConfigurationId;
        params.finalRosterId = finalRosterId;

        return this.http.getFileData(`${this.integratedReportingApiEndpoint}/technical-meeting-extract-club`, params as { [key: string]: string | string[] });
    }

    public downloadRosterReportPerPeriod(
        reportType: string,
        competitionId: number,
        competitionTeamIds: number[],
        dateFrom: string,
        dateTo: string,
        gameDay: number): Observable<IFileData> {
        const params = {} as ITelerikRosterReportParams;

        params.rp_DateFrom = dateFrom;
        params.rp_DateTo = dateTo;
        params.rp_GameDay = gameDay;
        params.rp_Competition = competitionId;        
        params.rp_CompetitionTeam = competitionTeamIds.join(',');
        params.rp_TypeDelegationRoster = reportType;
        params.rp_PlayersCoachs = 'P&C'; // All of our reports are requests are for players & coaches now.
        //params.competitionTeamCodes = competitionTeamCodes.join(',');

        return this.http.getFileData(`${this.integratedReportingApiEndpoint}/preliminary-final-rosters-players-per-period`,
            params as { [key: string]: string | string[] });
    }

    public downloadRosterReport(
        reportType: string,
        competitionId: number,
        competitionLevel: string): Observable<IFileData> {
        const params = {} as ITelerikRosterReportParams;

        params.rp_Competition = competitionId;
        params.rp_CompetitionLevel = competitionLevel;
        params.rp_TypeDelegationRoster = reportType;
        params.rp_PlayersCoachs = 'P&C';

        return this.http.getFileData(`${this.integratedReportingApiEndpoint}/preliminary-final-rosters-players`,
            params as { [key: string]: string | string[] });
    }

    public downloadStartListReport(gameId: number): Observable<IFileData> {
        const params = {} as IStartListReportParams;
        params.gameId = gameId;
        return this.http.getFileData(`${this.integratedReportingApiEndpoint}/start-list`,
            params as { [key: string]: string | string[] });
    }

    public downloadBoxScoreReport(competitionId: number, gameId: number,): Observable<IFileData> {
        const params = {} as IBoxScoreReportParams;

        params.competitionId = competitionId;
        params.gameId = gameId;

        return this.http.getFileData(`${this.integratedReportingApiEndpoint}/box-score`,
            params as { [key: string]: string | string[] });
    }

    public downloadPlayByPlayReport(competitionId: number, gameId: number, season: number, competitionCode: string, gameName: string): Observable<IFileData> {
        const params = {} as IPlayByPlayReportParams;

        params.competitionId = competitionId;
        params.gameId = gameId;
        params.season = season;
        params.competitionCode = competitionCode;
        params.gameName = encodeURI(gameName);

        return this.http.getFileData(`${this.integratedReportingApiEndpoint}/play-by-play`,
            params as { [key: string]: string | string[] });
    }

    public downloadLineupAnalysisReport(competitionId: number, gameId: number, season: number, competitionCode: string, gameName: string): Observable<IFileData> {
        const params = {} as ILineupAnaylsisReportParams;

        params.competitionId = competitionId;
        params.gameId = gameId;
        params.season = season;
        params.competitionCode = competitionCode;
        params.gameName = encodeURI(gameName);

        return this.http.getFileData(`${this.integratedReportingApiEndpoint}/lineup-analysis`,
            params as { [key: string]: string | string[] });
    }

    public downloadShotChartReport(competitionId: number, gameId: number, season: number, competitionCode: string, gameName: string): Observable<IFileData> {
        const params = {} as IShotChartReportParams;

        params.competitionId = competitionId;
        params.gameId = gameId;
        params.season = season;
        params.competitionCode = competitionCode;
        params.gameName = encodeURI(gameName);

        return this.http.getFileData(`${this.integratedReportingApiEndpoint}/shot-chart`,
            params as { [key: string]: string | string[] });
    }

    public downloadCompetitionScheduleReport(competitionId: number): Observable<IFileData> {
        const params = {} as ICompetitionScheduleReportParams;

        params.competitionId = competitionId;

        return this.http.getFileData(`${this.integratedReportingApiEndpoint}/competition-schedule`,
            params as { [key: string]: string | string[] });
    }

    public downloadTournamentSummaryReport(competitionId: number): Observable<IFileData> {
        const params = {} as ITournamentSummaryReportParams;

        params.competitionId = competitionId;

        return this.http.getFileData(`${this.integratedReportingApiEndpoint}/tournament-summary`,
            params as { [key: string]: string | string[] });
    }

    public downloadTeamStatisticsReport(competitionId: number, season: number, competitionCode: string, competitionLogoUrl: string, date: string) {
        const params = {} as ITeamStatisticsReportParams;

        params.competitionId = competitionId;
        params.competitionLogoUrl = competitionLogoUrl;
        params.competitionCode = competitionCode;
        params.date = date;
        params.season = season;

        return this.http.getFileData(`${this.integratedReportingApiEndpoint}/team-statistics`,
            params as { [key: string]: string | string[] });
    }

    public downloadPlayerStatisticsReport(competitionId: number, season: number, competitionLogoUrl: string, date: string) {
        const params = {} as IPlayerStatisticsReportParams;

        params.competitionId = competitionId;
        params.competitionLogoUrl = competitionLogoUrl;
        params.date = date;
        params.season = season;

        return this.http.getFileData(`${this.integratedReportingApiEndpoint}/player-statistics`,
            params as { [key: string]: string | string[] });
    }

    public downloadHeadToHeadReport(competitionId: number) {
        const params = {} as IHeadToHeadReportParams;

        params.competitionId = competitionId;

        return this.http.getFileData(`${this.integratedReportingApiEndpoint}/head-to-head`,
            params as { [key: string]: string | string[] });
    }

    public downloadLetterOfClearanceReport(transferLetterId: number, transferRequestId: number) {
        const params = {} as ILetterOfClearanceParams;

        params.transferLetterId = transferLetterId;
        params.transferRequestId = transferRequestId;

        return this.http.getFileData(`${this.integratedReportingApiEndpoint}/letter-of-clearance`,
            params as { [key: string]: string | string[] });
    }

    public downloadAccumulatedStatisticsReport(competitionId: number) {
        const params = {} as IAccumulatedStatisticsReportParams;

        params.competitionId = competitionId;
        return this.http.getFileData(`${this.integratedReportingApiEndpoint}/accumulated-statistics`,
            params as { [key: string]: string | string[] });
    }

    public downloadRosterDownloadReport(competitionId: number, preliminaryRosterStructureLevelId: number, gameDay: number, competitionTeamIds: number[] = null, isGameReportsContext: boolean = false) {
        const params = {} as IRosterDownloadReportParams;

        params.competitionId = competitionId;
        params.preliminaryRosterStructureLevelId = preliminaryRosterStructureLevelId;
        //params.roundId = roundId;
        //params.windowId = windowId;
        params.gameDay = gameDay;
        params.competitionTeamIds = competitionTeamIds;
        params.isGameReportsContext = isGameReportsContext;

        return this.http.getFileData(`${this.integratedReportingApiEndpoint}/roster-download`, params as { [key: string]: string | string[] });
    }

    public downloadPlayerInsuranceCoverageReport(preliminaryRosterMemberId: number) {
        const params = {} as IPlayerInsuranceCoverageParams;

        params.preliminaryRosterMemberId = preliminaryRosterMemberId;

        return this.http.getFileData(`${this.integratedReportingApiEndpoint}/insurance-coverage-information/player-roster`, params as { [key: string]: string | string[] });
    }

    public downloadTeamInsuranceCoverageReport(preliminaryRosterId: number) {
        const params = {} as ITeamInsuranceCoverageParams;

        params.preliminaryRosterId = preliminaryRosterId;

        return this.http.getFileData(`${this.integratedReportingApiEndpoint}/insurance-coverage-information/team-roster`, params as { [key: string]: string | string[] });
    }


    public fetchAlfrescoUrl(): Observable<ReportDTO> {
        
        return this.http.get(`${this.reportingApiEndpoint}/alfresco/login`);
    }

    public fetchTechReporting(): Observable<TechReportingDTO[]> {
        return this.http.get(`${this.reportingApiEndpoint}/list`);
    }

    //private getFileWithAlfrescoUrlParams(url: string, params: { [key: string]: string | string[] }): Observable<IFileData> {
    //    params.reportingImageApiEndPoint = this.reportingImageApiEndPoint;
    //    return this.fetchAlfrescoUrl().pipe(flatMap(
    //        (response) => {
    //            params.alfrescoUrl = this.config.alfrescoBaseContentUrl + response.alfrescoUrl;

    //            return this.http.getFileData(url, params);
    //        },
    //    ));
    //}
    public downloadCompetitionInsuredPlayersReport(preliminaryRosterConfigurationId: number) {
        const params = {} as ICompetitionInsuredPlayersParams;

        params.preliminaryRosterConfigurationId = preliminaryRosterConfigurationId;

        return this.http.getFileData(`${this.integratedReportingApiEndpoint}/insurance-coverage-information/competition-insured-players-extract`, params as { [key: string]: string | string[] });
    }


    public downloadSiteVisitFormReport(siteVisitFormRegistrationId: number, isBlankReport: boolean): Observable<IFileData> {
        const params = {} as ISiteVisitReportParams;

        params.siteVisitFormRegistrationId = siteVisitFormRegistrationId;
        params.isBlankReport = isBlankReport;

        return this.http.getFileData(`${this.integratedReportingApiEndpoint}/site-visit-form`, params as { [key: string]: string | string[] });
    }


    public downloadFormRegistrationReport(formRegistrationId: number, isBlankReport: boolean): Observable<IFileData> {
        const params = {} as IFormRegistrationReportParams;

        params.formRegistrationId = formRegistrationId;
        params.isBlankReport = isBlankReport;

        return this.http.getFileData(`${this.integratedReportingApiEndpoint}/form-registration`, params as { [key: string]: string | string[] });
    }

    public downloadTechnicalMeetingOperatorLastLoginReport(competitionId: number): Observable<IFileData> {
        const params = {} as ITechnicalMeetingOperatorLastLoginReportParams;

        params.competitionId = competitionId;

        return this.http.getFileData(`${this.integratedReportingApiEndpoint}/technical-meeting-operateur-last-login`, params as { [key: string]: string | string[] });
    }

    public downloadVenueFormRegistrationsReport(): Observable<IFileData> {
        return this.http.getFileData(`${this.integratedReportingApiEndpoint}/venue-form-registrations`);
    }

    public downloadRankingReport(rankingId: number) {
        const params = {} as IRankingReportParams;

        params.rankingId = rankingId;

        return this.http.getFileData(`${this.integratedReportingApiEndpoint}/ranking`, params as { [key: string]: string | string[] });
    }

    public downloadPotentialGroupRankingReport(groupPairingIds: number[]) {
        const params = {} as IPotentialGroupRankingReportParams;
        params.groupPairingIds = groupPairingIds;
        return this.http.getFileData(`${this.integratedReportingApiEndpoint}/potential-group-ranking`, params as { [key: string]: string | string[] });
    }

    public downloadAgreementReport(equipmentAgreementId: number) {
        const params = {} as IAgreementReportParams;
        params.equipmentAgreementId = equipmentAgreementId;
        return this.http.getFileData(`${this.integratedReportingApiEndpoint}/agreement`, params as { [key: string]: string | string[] });
    }

    public downloadCertificateReport(equipmentAgreementId: number,equipmentApprovalCategoryId: number) {
        const params = {} as IAgreementReportParams;
        params.equipmentAgreementId = equipmentAgreementId;
        params.equipmentApprovalCategoryId = equipmentApprovalCategoryId;
        return this.http.getFileData(`${this.integratedReportingApiEndpoint}/agreement-certificate`, params as { [key: string]: string | string[] });
    }

}
