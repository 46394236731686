import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiListResponse } from '@fiba/models';

import { HttpService } from '@fiba/data-services/utils/http.service';
import { AppConfig } from '@app/app.config';
import { EquipmentAgreementDTO } from '../../models/dtos/generated/equipment-agreement-dto';
import { AgreementFilters } from '../../models/equipment/agreement';
import { EquipmentAgreementStatusDTO } from '../../models/dtos/generated/equipment-agreement-status-dto';
import { EquipmentAgreementDocumentDTO } from '../../models/dtos/generated/equipment-agreement-document-dto';

@Injectable({
    providedIn: 'root',
})
export class AgreementDataService {
    protected equipmentAgreementApiEndpoint: string;

    constructor(
        protected config: AppConfig,
        protected http: HttpService) {
        this.equipmentAgreementApiEndpoint = config.equipmentAgreementApiEndpoint;
    }

    public fetchList(filters: AgreementFilters): Observable<ApiListResponse<EquipmentAgreementDTO>> {
        return this.http.get(`${this.equipmentAgreementApiEndpoint}/list-agreement`, filters.getSearchParams());
    }

    public createEquipmentAgreementInformation(model: EquipmentAgreementDTO, filters: AgreementFilters): Observable<ApiListResponse<EquipmentAgreementDTO>> {
        return this.http.post(`${this.equipmentAgreementApiEndpoint}`, model, filters.getSearchParams());
    }

    public fetchStatuses(): Observable<EquipmentAgreementStatusDTO[]> {
        return this.http.get(`${this.equipmentAgreementApiEndpoint}/statuses`);
    }

    public createAgreementDocument(equipmenAgreementDocumentDTO: EquipmentAgreementDocumentDTO, equipmentAgreementStatusCode) {
        return this.http.post(`${this.equipmentAgreementApiEndpoint}/agreement-document/${equipmentAgreementStatusCode}`, equipmenAgreementDocumentDTO);
    }

    public saveAgreementDocument(equipmenAgreementDocumentDTO: EquipmentAgreementDocumentDTO, equipmentAgreementStatusCode) {
        return this.http.put(`${this.equipmentAgreementApiEndpoint}/agreement-document/${equipmentAgreementStatusCode}`, equipmenAgreementDocumentDTO);
    }
}
