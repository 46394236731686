import {HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

// External modules
import {DialogService} from '@progress/kendo-angular-dialog';

// New architecture
import {FibaCommonModule} from '@fiba/common';
import {FibaCommonSanctionModule} from '@fiba/common/fiba-common-sanction.module';
import {FibaCommonSelectModule} from '@fiba/common/fiba-common-select.module';
import {FibaFormsModule} from '@fiba/forms';

// TODO: split dependencies into related files instead of globally
import {AppComponent} from './app.component';
import {AuthHandlerSilentComponent} from './components/auth-handler-silent.component';
import {BreadcrumbComponent} from './components/breadcrumb.component';
import {ChangePasswordComponent} from './components/change-password.component';
import {DialogActionComponent} from './components/dialog-action.component';
import {DummyRoutingComponent} from './components/dummy-routing.component';
import {NavMenuComponent} from './components/navmenu.component';
import {NotificationDisplayWrapperComponent} from './components/notification-display-wrapper.component';
import {NotificationDisplayComponent} from './components/notification-display.component';
import {RootComponent} from './components/root.component';
import {TermsAndConditionsComponent} from './components/terms-and-conditions.component';
import {SharedModule} from './shared.module';

// Service
import {AuthHandlerModule} from './components/auth-handler/auth-handler.module';
import {OfficialLicenseDataService} from '@fiba/data-services/official-license/official-license-data.service';
import {TopMenuModule} from './components/topmenu/topmenu.module';
import { TimeoutInterceptor } from '../@fiba/data-services/utils/interceptos/timeout-interceptor';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';


@NgModule({
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        SharedModule.forRoot(),
        HttpClientModule,
        HttpClientJsonpModule,
        BrowserAnimationsModule,
        NgxIntlTelInputModule,

        // Remove when core has no component to declare anymore
        FibaCommonModule,
        FibaCommonSanctionModule,
        FibaFormsModule,
        FibaCommonSelectModule,

        // ToKeep
        TopMenuModule,
        AuthHandlerModule,
    ],
    declarations: [
        AppComponent,
        RootComponent,
        NavMenuComponent,
        BreadcrumbComponent,
        NotificationDisplayComponent,
        NotificationDisplayWrapperComponent,
        DialogActionComponent,
        AuthHandlerSilentComponent,
        TermsAndConditionsComponent,
        ChangePasswordComponent,
        DummyRoutingComponent,
    ],
    providers: [
        DialogService,
        OfficialLicenseDataService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TimeoutInterceptor,
            multi: true,
        },
    ],
})

export class CoreModule {
}
